const applicationData = [
  {
    applicationID: 50867,
    applicationType: "New",
    applicationStatusID: 3,
    applicationStatus: "Items Needed",
  },
  {
    applicationID: 40685,
    applicationType: "Renewal",
    applicationStatusID: 2,
    applicationStatus: "Not Submitted",
  },
  {
    applicationID: 40644,
    applicationType: "ACHCU Education",
    applicationStatusID: 1,
    applicationStatus: "Submitted",
  },
  {
    applicationID: 40723,
    applicationType: "Renewal",
    applicationStatusID: 2,
    applicationStatus: "Not Submitted",
  },
];

export default applicationData;

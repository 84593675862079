var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"sm":"12","xl":"12"}},[_c('b-row',{staticClass:"mb-4 mt-4"},[_c('b-col',[_c('div',[_c('h1',{staticClass:"bold-header-text app-header"},[_vm._v(_vm._s(_vm.title))])]),_c('b-card',{staticClass:"dashboard-card shadow-sm",staticStyle:{"margin-top":"0.78rem"}},[_c('div',[_c('h5',{staticClass:"bold-header-text"},[_vm._v("Demographic")]),_c('b-row',{staticClass:"formrow"},[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Company Legal Name"}},[_c('b-form-input',{attrs:{"state":_vm.$v.company.companyLegalName.$dirty
                        ? !_vm.$v.company.companyLegalName.$error
                        : null},on:{"input":_vm.$v.company.companyLegalName.$touch},model:{value:(_vm.company.companyLegalName),callback:function ($$v) {_vm.$set(_vm.company, "companyLegalName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"company.companyLegalName"}}),(!_vm.$v.company.companyLegalName.maxLength)?_c('div',{staticClass:"text-danger"},[_vm._v(" Must be fewer than 75 characters. ")]):_vm._e(),(
                      !_vm.$v.company.companyLegalName.required && _vm.$v.company.companyLegalName.$dirty
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" This field is required. ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Company DBA Name"}},[_c('b-form-input',{attrs:{"state":_vm.$v.company.dbaCompanyName.$dirty ? !_vm.$v.company.dbaCompanyName.$error : null},on:{"input":_vm.$v.company.dbaCompanyName.$touch},model:{value:(_vm.company.dbaCompanyName),callback:function ($$v) {_vm.$set(_vm.company, "dbaCompanyName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"company.dbaCompanyName"}}),(!_vm.$v.company.dbaCompanyName.maxLength)?_c('div',{staticClass:"text-danger"},[_vm._v(" Must be fewer than 75 characters. ")]):_vm._e()],1)],1)],1),_c('b-row',{staticClass:"formrow"},[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Program(s) of Interest"}},[_c('multiselect',{class:{
                      'multiselect-is-invalid': _vm.$v.company.programsOfInterest.$dirty
                        ? _vm.$v.company.programsOfInterest.$error
                        : null,
                    },attrs:{"options":_vm.groupedPrograms,"multiple":true,"close-on-select":false,"placeholder":"Select Programs...","track-by":"programID","group-values":"options","group-label":"label","label":"programDescription"},on:{"input":_vm.$v.company.programsOfInterest.$touch},scopedSlots:_vm._u([{key:"option",fn:function(props){return [(props.option.$isLabel)?_c('span',{staticClass:"option__title",attrs:{"title":props.option.$groupLabel}},[_vm._v(_vm._s(props.option.$groupLabel))]):_c('span',{staticClass:"option__title",attrs:{"title":props.option.programDetailDescription}},[_vm._v(_vm._s(props.option.programDescription))])]}}]),model:{value:(_vm.company.programsOfInterest),callback:function ($$v) {_vm.$set(_vm.company, "programsOfInterest", $$v)},expression:"company.programsOfInterest"}}),(
                      !_vm.$v.company.programsOfInterest.required &&
                      _vm.$v.company.programsOfInterest.$dirty
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" This field is required. ")]):_vm._e()],1)],1),(this.$route.name === 'AddNewCompany')?_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Number of Locations"}},[_c('b-form-input',{model:{value:(_vm.$v.company.numberofLocations.$model),callback:function ($$v) {_vm.$set(_vm.$v.company.numberofLocations, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.company.numberofLocations.$model"}}),(!_vm.$v.company.numberofLocations.numeric)?_c('div',{staticClass:"text-danger",attrs:{"state":!_vm.$v.company.numberofLocations.$error}},[_vm._v(" Must be a number. ")]):_vm._e()],1)],1):_vm._e()],1),_c('hr'),_c('h5',{staticClass:"bold-header-text mt-4"},[_vm._v("Company Address")]),_c('b-row',{staticClass:"formrow"},[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Street Address 1"}},[_c('vue-google-autocomplete',{ref:"autocomplete",attrs:{"id":"map","classname":"form-control","placeholder":"Search Here...","country":"us"},on:{"placechanged":_vm.getAddressData,"change":_vm.handleAddressChange}}),(
                      !_vm.$v.company.address.addressLine1.required &&
                      _vm.$v.company.address.addressLine1.$dirty
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" This field is required. ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Street Address 2"}},[_c('b-form-input',{staticClass:"col-sm-10",attrs:{"id":"street-address-2"},model:{value:(_vm.company.address.addressLine2),callback:function ($$v) {_vm.$set(_vm.company.address, "addressLine2", $$v)},expression:"company.address.addressLine2"}})],1)],1)],1),_c('b-row',{staticClass:"formrow"},[_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"City"}},[_c('b-form-input',{staticClass:"col-sm-10",attrs:{"id":"city","state":_vm.$v.company.address.city.$dirty ? !_vm.$v.company.address.city.$error : null},on:{"input":_vm.$v.company.address.city.$touch},model:{value:(_vm.company.address.city),callback:function ($$v) {_vm.$set(_vm.company.address, "city", $$v)},expression:"company.address.city"}}),(!_vm.$v.company.address.city.required && _vm.$v.company.address.city.$dirty)?_c('div',{staticClass:"text-danger"},[_vm._v(" This field is required. ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"State"}},[_c('b-form-select',{attrs:{"state":_vm.$v.company.address.stateID.$dirty
                        ? !_vm.$v.company.address.stateID.$error
                        : null,"options":_vm.states,"value-field":"stateID","text-field":"stateCode","plain":""},on:{"input":_vm.$v.company.address.stateID.$touch},model:{value:(_vm.company.address.stateID),callback:function ($$v) {_vm.$set(_vm.company.address, "stateID", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"company.address.stateID"}}),(
                      !_vm.$v.company.address.stateID.required && _vm.$v.company.address.stateID.$dirty
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" This field is required. ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Zip Code"}},[_c('b-form-input',{attrs:{"state":_vm.$v.company.address.zip.$dirty ? !_vm.$v.company.address.zip.$error : null},on:{"input":_vm.$v.company.address.zip.$touch},model:{value:(_vm.company.address.zip),callback:function ($$v) {_vm.$set(_vm.company.address, "zip", $$v)},expression:"company.address.zip"}}),(!_vm.$v.company.address.zip.required && _vm.$v.company.address.zip.$dirty)?_c('div',{staticClass:"text-danger"},[_vm._v(" This field is required. ")]):_vm._e(),(!_vm.$v.company.address.zip.maxLength)?_c('div',{staticClass:"text-danger"},[_vm._v(" Maximum Characters Allowed 10. ")]):_vm._e()],1)],1)],1),_c('hr'),_c('h5',{staticClass:"bold-header-text mt-4"},[_vm._v("Contact Info")]),_c('b-row',{staticClass:"formrow"},[_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Salutation"}},[_c('b-form-input',{staticClass:"col-sm-10",attrs:{"id":"nested-salutation","state":_vm.$v.company.contact.salutation.$dirty
                        ? !_vm.$v.company.contact.salutation.$error
                        : null,"placeholder":"Salutation"},on:{"input":_vm.$v.company.contact.salutation.$touch},model:{value:(_vm.company.contact.salutation),callback:function ($$v) {_vm.$set(_vm.company.contact, "salutation", $$v)},expression:"company.contact.salutation"}}),(
                      !_vm.$v.company.contact.salutation.required &&
                      _vm.$v.company.contact.salutation.$dirty
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" This field is required. ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"First Name"}},[_c('b-form-input',{staticClass:"col-sm-10",attrs:{"id":"nested-firstName","state":_vm.$v.company.contact.firstName.$dirty
                        ? !_vm.$v.company.contact.firstName.$error
                        : null,"placeholder":"First Name"},on:{"input":_vm.$v.company.contact.firstName.$touch},model:{value:(_vm.company.contact.firstName),callback:function ($$v) {_vm.$set(_vm.company.contact, "firstName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"company.contact.firstName"}}),(!_vm.$v.company.contact.firstName.maxLength)?_c('div',{staticClass:"text-danger"},[_vm._v(" Maximum Characters Allowed 50. ")]):_vm._e(),(
                      !_vm.$v.company.contact.firstName.required &&
                      _vm.$v.company.contact.firstName.$dirty
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" This field is required. ")]):_vm._e()],1)],1)],1),_c('b-row',{staticClass:"formrow"},[_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Last Name"}},[_c('b-form-input',{staticClass:"col-sm-10",attrs:{"id":"nested-lastName","state":_vm.$v.company.contact.lastName.$dirty
                        ? !_vm.$v.company.contact.lastName.$error
                        : null,"placeholder":"Last Name"},on:{"input":_vm.$v.company.contact.lastName.$touch},model:{value:(_vm.company.contact.lastName),callback:function ($$v) {_vm.$set(_vm.company.contact, "lastName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"company.contact.lastName"}}),(!_vm.$v.company.contact.lastName.maxLength)?_c('div',{staticClass:"text-danger"},[_vm._v(" Maximum Characters Allowed 50. ")]):_vm._e(),(
                      !_vm.$v.company.contact.lastName.required && _vm.$v.company.contact.lastName.$dirty
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" This field is required. ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Title"}},[_c('b-form-input',{staticClass:"col-sm-10",attrs:{"id":"nested-title","state":_vm.$v.company.contact.title.$dirty ? !_vm.$v.company.contact.title.$error : null,"placeholder":"Title"},on:{"input":_vm.$v.company.contact.title.$touch},model:{value:(_vm.company.contact.title),callback:function ($$v) {_vm.$set(_vm.company.contact, "title", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"company.contact.title"}}),(!_vm.$v.company.contact.title.maxLength)?_c('div',{staticClass:"text-danger"},[_vm._v(" Maximum Characters Allowed 50. ")]):_vm._e(),(!_vm.$v.company.contact.title.required && _vm.$v.company.contact.title.$dirty)?_c('div',{staticClass:"text-danger"},[_vm._v(" This field is required. ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Email"}},[_c('b-form-input',{staticClass:"col-sm-6",attrs:{"id":"nested-emailaddress","state":_vm.$v.company.contact.emailAddress.$dirty
                        ? !_vm.$v.company.contact.emailAddress.$error
                        : null,"placeholder":"Email"},on:{"input":_vm.$v.company.contact.emailAddress.$touch},model:{value:(_vm.company.contact.emailAddress),callback:function ($$v) {_vm.$set(_vm.company.contact, "emailAddress", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"company.contact.emailAddress"}}),(!_vm.$v.company.contact.emailAddress.email)?_c('div',{staticClass:"text-danger"},[_vm._v(" Enter a valid email address. ")]):(!_vm.$v.company.contact.emailAddress.maxLength)?_c('div',{staticClass:"text-danger"},[_vm._v(" Maximum Characters Allowed 50. ")]):_vm._e(),(
                      !_vm.$v.company.contact.emailAddress.required &&
                      _vm.$v.company.contact.emailAddress.$dirty
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" This field is required. ")]):_vm._e()],1)],1)],1),_c('b-row',{staticClass:"formrow"},[_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Phone Number"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.phoneMask),expression:"phoneMask"}],staticClass:"col-sm-10",attrs:{"id":"nested-phoneNumber","state":_vm.$v.company.phone.phoneNumber.$dirty
                        ? !_vm.$v.company.phone.phoneNumber.$error
                        : null,"placeholder":"Phone Number"},on:{"input":_vm.$v.company.phone.phoneNumber.$touch},model:{value:(_vm.company.phone.phoneNumber),callback:function ($$v) {_vm.$set(_vm.company.phone, "phoneNumber", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"company.phone.phoneNumber"}}),(!_vm.$v.company.phone.phoneNumber.minLength)?_c('div',{staticClass:"text-danger"},[_vm._v(" Must have at least 10 digits. ")]):_vm._e(),(
                      !_vm.$v.company.phone.phoneNumber.required &&
                      _vm.$v.company.phone.phoneNumber.$dirty
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" This field is required. ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Ext."}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.extMask),expression:"extMask"}],staticClass:"col-sm-10",attrs:{"id":"nested-phoneNumberExtension","state":_vm.$v.company.phone.phoneNumberExtension.$dirty
                        ? !_vm.$v.company.phone.phoneNumberExtension.$error
                        : null,"placeholder":"Ext."},on:{"input":_vm.$v.company.phone.phoneNumberExtension.$touch},model:{value:(_vm.company.phone.phoneNumberExtension),callback:function ($$v) {_vm.$set(_vm.company.phone, "phoneNumberExtension", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"company.phone.phoneNumberExtension"}}),(!_vm.$v.company.phone.phoneNumberExtension.maxLength)?_c('div',{staticClass:"text-danger"},[_vm._v(" Must have at most "+_vm._s(_vm.$v.company.phone.phoneNumberExtension.$params.maxLength.max)+" digits. ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Phone Number Type"}},[_c('b-form-select',{staticClass:"form-select",attrs:{"state":_vm.$v.company.phone.phoneNumberType.$dirty
                        ? !_vm.$v.company.phone.phoneNumberType.$error
                        : null,"options":_vm.phoneTypes,"value-field":"phoneNumberTypeID","text-field":"phoneNumberTypeDesc"},on:{"input":_vm.$v.company.phone.phoneNumberType.$touch},model:{value:(_vm.company.phone.phoneNumberType),callback:function ($$v) {_vm.$set(_vm.company.phone, "phoneNumberType", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"company.phone.phoneNumberType"}}),(
                      _vm.$v.company.phone.phoneNumberType.required &&
                      !_vm.$v.company.phone.phoneNumberType.$dirty
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" This field is required. ")]):_vm._e()],1)],1)],1),_c('b-row',{attrs:{"align-h":"end","align-content":"end"}},[_c('b-col',{staticClass:"mt-2",attrs:{"xs":"12","sm":"6","md":"6","lg":"6","xl":"3","align-self":"end"}},[_c('div',{staticClass:"data-block application-button-block"},[_c('button',{staticClass:"danger-button",on:{"click":function($event){return _vm.cancelForm()}}},[_vm._v(" "+_vm._s(_vm.$t("forms.cancel"))+" ")])])]),_c('b-col',{staticClass:"mt-2",attrs:{"xs":"12","sm":"6","md":"6","lg":"6","xl":"3","align-self":"end"}},[_c('div',{staticClass:"data-block application-button-block"},[_c('button',{staticClass:"base-button",on:{"click":_vm.onValidateFormSubmit}},[_vm._v(" "+_vm._s(_vm.$t("forms.save"))+" ")])])])],1)],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
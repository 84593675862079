<template>
  <b-row>
    <b-col sm="12" xl="12">
      <b-row class="mb-4 mt-4">
        <b-col>
          <div>
            <h1 class="bold-header-text app-header">{{ title }}</h1>
          </div>
          <b-card class="dashboard-card shadow-sm" style="margin-top: 0.78rem">
            <div>
              <!-- Demographic start -->
              <h5 class="bold-header-text">Demographic</h5>
              <b-row class="formrow">
                <b-col lg="6">
                  <b-form-group label="Company Legal Name">
                    <b-form-input
                      v-model.trim="company.companyLegalName"
                      :state="
                        $v.company.companyLegalName.$dirty
                          ? !$v.company.companyLegalName.$error
                          : null
                      "
                      @input="$v.company.companyLegalName.$touch"
                    ></b-form-input>
                    <div class="text-danger" v-if="!$v.company.companyLegalName.maxLength">
                      Must be fewer than 75 characters.
                    </div>
                    <div
                      class="text-danger"
                      v-if="
                        !$v.company.companyLegalName.required && $v.company.companyLegalName.$dirty
                      "
                    >
                      This field is required.
                    </div>
                  </b-form-group>
                </b-col>
                <b-col lg="6">
                  <b-form-group label="Company DBA Name">
                    <b-form-input
                      v-model.trim="company.dbaCompanyName"
                      :state="
                        $v.company.dbaCompanyName.$dirty ? !$v.company.dbaCompanyName.$error : null
                      "
                      @input="$v.company.dbaCompanyName.$touch"
                    ></b-form-input>
                    <div class="text-danger" v-if="!$v.company.dbaCompanyName.maxLength">
                      Must be fewer than 75 characters.
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="formrow">
                <b-col lg="6">
                  <b-form-group label="Program(s) of Interest">
                    <multiselect
                      v-model="company.programsOfInterest"
                      :class="{
                        'multiselect-is-invalid': $v.company.programsOfInterest.$dirty
                          ? $v.company.programsOfInterest.$error
                          : null,
                      }"
                      :options="groupedPrograms"
                      :multiple="true"
                      :close-on-select="false"
                      placeholder="Select Programs..."
                      track-by="programID"
                      group-values="options"
                      group-label="label"
                      label="programDescription"
                      @input="$v.company.programsOfInterest.$touch"
                    >
                      <template slot="option" slot-scope="props">
                        <span
                          v-if="props.option.$isLabel"
                          class="option__title"
                          :title="props.option.$groupLabel"
                          >{{ props.option.$groupLabel }}</span
                        >
                        <span
                          v-else
                          class="option__title"
                          :title="props.option.programDetailDescription"
                          >{{ props.option.programDescription }}</span
                        >
                      </template>
                    </multiselect>
                    <div
                      class="text-danger"
                      v-if="
                        !$v.company.programsOfInterest.required &&
                        $v.company.programsOfInterest.$dirty
                      "
                    >
                      This field is required.
                    </div>
                  </b-form-group>
                </b-col>
                <b-col lg="6" v-if="this.$route.name === 'AddNewCompany'">
                  <b-form-group label="Number of Locations">
                    <b-form-input v-model.trim="$v.company.numberofLocations.$model" />
                    <div
                      class="text-danger"
                      :state="!$v.company.numberofLocations.$error"
                      v-if="!$v.company.numberofLocations.numeric"
                    >
                      Must be a number.
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- Demographic End -->
              <hr />
              <!-- Address info Start -->
              <h5 class="bold-header-text mt-4">Company Address</h5>
              <b-row class="formrow">
                <b-col lg="6">
                  <b-form-group label="Street Address 1">
                    <vue-google-autocomplete
                      id="map"
                      ref="autocomplete"
                      classname="form-control"
                      placeholder="Search Here..."
                      v-on:placechanged="getAddressData"
                      v-on:change="handleAddressChange"
                      country="us"
                    >
                    </vue-google-autocomplete>
                    <div
                      class="text-danger"
                      v-if="
                        !$v.company.address.addressLine1.required &&
                        $v.company.address.addressLine1.$dirty
                      "
                    >
                      This field is required.
                    </div>
                  </b-form-group>
                </b-col>
                <b-col lg="6">
                  <b-form-group label="Street Address 2">
                    <b-form-input
                      id="street-address-2"
                      class="col-sm-10"
                      v-model="company.address.addressLine2"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="formrow">
                <b-col lg="4">
                  <b-form-group label="City">
                    <b-form-input
                      id="city"
                      class="col-sm-10"
                      v-model="company.address.city"
                      :state="
                        $v.company.address.city.$dirty ? !$v.company.address.city.$error : null
                      "
                      @input="$v.company.address.city.$touch"
                    >
                    </b-form-input>
                    <div
                      class="text-danger"
                      v-if="!$v.company.address.city.required && $v.company.address.city.$dirty"
                    >
                      This field is required.
                    </div>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group label="State">
                    <b-form-select
                      v-model.trim="company.address.stateID"
                      :state="
                        $v.company.address.stateID.$dirty
                          ? !$v.company.address.stateID.$error
                          : null
                      "
                      :options="states"
                      value-field="stateID"
                      text-field="stateCode"
                      plain
                      @input="$v.company.address.stateID.$touch"
                    ></b-form-select>
                    <div
                      class="text-danger"
                      v-if="
                        !$v.company.address.stateID.required && $v.company.address.stateID.$dirty
                      "
                    >
                      This field is required.
                    </div>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group label="Zip Code">
                    <b-form-input
                      v-model="company.address.zip"
                      :state="$v.company.address.zip.$dirty ? !$v.company.address.zip.$error : null"
                      @input="$v.company.address.zip.$touch"
                    ></b-form-input>
                    <div
                      class="text-danger"
                      v-if="!$v.company.address.zip.required && $v.company.address.zip.$dirty"
                    >
                      This field is required.
                    </div>
                    <div class="text-danger" v-if="!$v.company.address.zip.maxLength">
                      Maximum Characters Allowed 10.
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- Address info End -->
              <hr />
              <!--Contact Info start -->
              <h5 class="bold-header-text mt-4">Contact Info</h5>
              <b-row class="formrow">
                <b-col lg="4">
                  <b-form-group label="Salutation">
                    <b-form-input
                      id="nested-salutation"
                      class="col-sm-10"
                      v-model="company.contact.salutation"
                      :state="
                        $v.company.contact.salutation.$dirty
                          ? !$v.company.contact.salutation.$error
                          : null
                      "
                      placeholder="Salutation"
                      @input="$v.company.contact.salutation.$touch"
                    >
                    </b-form-input>
                    <div
                      class="text-danger"
                      v-if="
                        !$v.company.contact.salutation.required &&
                        $v.company.contact.salutation.$dirty
                      "
                    >
                      This field is required.
                    </div>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group label="First Name">
                    <b-form-input
                      id="nested-firstName"
                      class="col-sm-10"
                      v-model.trim="company.contact.firstName"
                      :state="
                        $v.company.contact.firstName.$dirty
                          ? !$v.company.contact.firstName.$error
                          : null
                      "
                      placeholder="First Name"
                      @input="$v.company.contact.firstName.$touch"
                    ></b-form-input>
                    <!-- <div class="text-danger" v-if="!$v.company.contact.firstName.alpha">
                      First Name must be composed only with letters!
                    </div> -->
                    <div class="text-danger" v-if="!$v.company.contact.firstName.maxLength">
                      Maximum Characters Allowed 50.
                    </div>
                    <div
                      class="text-danger"
                      v-if="
                        !$v.company.contact.firstName.required &&
                        $v.company.contact.firstName.$dirty
                      "
                    >
                      This field is required.
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="formrow">
                <b-col lg="4">
                  <b-form-group label="Last Name">
                    <b-form-input
                      id="nested-lastName"
                      class="col-sm-10"
                      v-model.trim="company.contact.lastName"
                      :state="
                        $v.company.contact.lastName.$dirty
                          ? !$v.company.contact.lastName.$error
                          : null
                      "
                      placeholder="Last Name"
                      @input="$v.company.contact.lastName.$touch"
                    ></b-form-input>

                    <!-- <div class="text-danger" v-if="!$v.company.contact.lastName.alpha">
                      Last Name must be composed only with letters!
                    </div> -->
                    <div class="text-danger" v-if="!$v.company.contact.lastName.maxLength">
                      Maximum Characters Allowed 50.
                    </div>
                    <div
                      class="text-danger"
                      v-if="
                        !$v.company.contact.lastName.required && $v.company.contact.lastName.$dirty
                      "
                    >
                      This field is required.
                    </div>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group label="Title">
                    <b-form-input
                      id="nested-title"
                      class="col-sm-10"
                      v-model.trim="company.contact.title"
                      :state="
                        $v.company.contact.title.$dirty ? !$v.company.contact.title.$error : null
                      "
                      placeholder="Title"
                      @input="$v.company.contact.title.$touch"
                    ></b-form-input>
                    <div class="text-danger" v-if="!$v.company.contact.title.maxLength">
                      Maximum Characters Allowed 50.
                    </div>
                    <div
                      class="text-danger"
                      v-if="!$v.company.contact.title.required && $v.company.contact.title.$dirty"
                    >
                      This field is required.
                    </div>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group label="Email">
                    <b-form-input
                      id="nested-emailaddress"
                      class="col-sm-6"
                      v-model.trim="company.contact.emailAddress"
                      :state="
                        $v.company.contact.emailAddress.$dirty
                          ? !$v.company.contact.emailAddress.$error
                          : null
                      "
                      @input="$v.company.contact.emailAddress.$touch"
                      placeholder="Email"
                    ></b-form-input>

                    <div class="text-danger" v-if="!$v.company.contact.emailAddress.email">
                      Enter a valid email address.
                    </div>
                    <div class="text-danger" v-else-if="!$v.company.contact.emailAddress.maxLength">
                      Maximum Characters Allowed 50.
                    </div>
                    <div
                      class="text-danger"
                      v-if="
                        !$v.company.contact.emailAddress.required &&
                        $v.company.contact.emailAddress.$dirty
                      "
                    >
                      This field is required.
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="formrow">
                <b-col lg="4">
                  <b-form-group label="Phone Number">
                    <b-form-input
                      v-mask="phoneMask"
                      id="nested-phoneNumber"
                      class="col-sm-10"
                      v-model.trim="company.phone.phoneNumber"
                      :state="
                        $v.company.phone.phoneNumber.$dirty
                          ? !$v.company.phone.phoneNumber.$error
                          : null
                      "
                      placeholder="Phone Number"
                      @input="$v.company.phone.phoneNumber.$touch"
                    ></b-form-input>
                    <div class="text-danger" v-if="!$v.company.phone.phoneNumber.minLength">
                      Must have at least 10 digits.
                    </div>
                    <div
                      class="text-danger"
                      v-if="
                        !$v.company.phone.phoneNumber.required &&
                        $v.company.phone.phoneNumber.$dirty
                      "
                    >
                      This field is required.
                    </div>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group label="Ext.">
                    <b-form-input
                      id="nested-phoneNumberExtension"
                      v-mask="extMask"
                      class="col-sm-10"
                      v-model.trim="company.phone.phoneNumberExtension"
                      :state="
                        $v.company.phone.phoneNumberExtension.$dirty
                          ? !$v.company.phone.phoneNumberExtension.$error
                          : null
                      "
                      @input="$v.company.phone.phoneNumberExtension.$touch"
                      placeholder="Ext."
                    ></b-form-input>
                    <div
                      class="text-danger"
                      v-if="!$v.company.phone.phoneNumberExtension.maxLength"
                    >
                      Must have at most
                      {{ $v.company.phone.phoneNumberExtension.$params.maxLength.max }}
                      digits.
                    </div>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group label="Phone Number Type">
                    <b-form-select
                      v-model.trim="company.phone.phoneNumberType"
                      :state="
                        $v.company.phone.phoneNumberType.$dirty
                          ? !$v.company.phone.phoneNumberType.$error
                          : null
                      "
                      @input="$v.company.phone.phoneNumberType.$touch"
                      :options="phoneTypes"
                      value-field="phoneNumberTypeID"
                      text-field="phoneNumberTypeDesc"
                      class="form-select"
                    ></b-form-select>
                    <div
                      class="text-danger"
                      v-if="
                        $v.company.phone.phoneNumberType.required &&
                        !$v.company.phone.phoneNumberType.$dirty
                      "
                    >
                      This field is required.
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row align-h="end" align-content="end">
                <b-col class="mt-2" xs="12" sm="6" md="6" lg="6" xl="3" align-self="end">
                  <div class="data-block application-button-block">
                    <button class="danger-button" @click="cancelForm()">
                      {{ $t("forms.cancel") }}
                    </button>
                  </div>
                </b-col>
                <b-col class="mt-2" xs="12" sm="6" md="6" lg="6" xl="3" align-self="end">
                  <div class="data-block application-button-block">
                    <button @click="onValidateFormSubmit" class="base-button">
                      {{ $t("forms.save") }}
                    </button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<style lang="scss" scoped>
.subtitle {
  margin-top: 2rem;
  font-size: 20px;
}
.full-width {
  width: 100%;
}

.application-button-block {
  display: flex;
  justify-content: center;
}
.application-button {
  color: #ffffff;
  border-radius: 30px;
  border: none;
  font-size: 0.9rem;
  font-weight: 700;
  @media (min-width: 600px) {
    padding: 10px 25%;
  }
  @media (max-width: 599px) {
    padding: 10px 40px;
  }
  transition: background-color 0.2s ease;
}
.application-button-block {
  display: flex;
  justify-content: center;
}
.data-block {
  @media (min-width: 600px) {
    flex-basis: 23%;
  }
  @media (max-width: 599px) {
    flex-basis: 100%;
  }
}
.active-application {
  background-color: #005175;
}
.active-application:hover {
  background-color: #74a2bf;
}
.buttons-block {
  margin-top: 1rem;
  justify-content: center;
}

.multiselect-is-invalid ::v-deep .multiselect__tags {
  border: 1px solid #dc3545 !important;
}
</style>
<script>
// Components

// Mixins
import CompanyMixin from "../../../mixins/CompanyMixin.vue";
import CompanyAddressMixin from "../../../mixins/CompanyAddressMixin.vue";
import PhoneMixin from "../../../mixins/PhoneMixin.vue";
import ProgramMixin from "../../../mixins/ProgramMixin.vue";
import DirtyDataMixin from "../../../mixins/DirtyDataMixin.vue";
import jwt_decode from "jwt-decode";

// Test Data
import applicationData from "../../../data/applicationData";
import recentNewsData from "../../../data/recentNewsData";
import { validationMixin } from "vuelidate";
import Multiselect from "vue-multiselect";
import { mapMutations } from "vuex";
import VueGoogleAutocomplete from "vue-google-autocomplete";
// For Demo
import NotificationsMixin from "../../../mixins/NotificationsMixin.vue";
// For Demo
import { bus } from "../../../main";

const {
  required,
  maxLength,
  email,
  // alpha,
  minLength,
  numeric,
} = require("vuelidate/lib/validators");

export default {
  data() {
    return {
      applications: applicationData,
      recentNews: recentNewsData,
      company: {},
    };
  },
  components: {
    multiselect: Multiselect,
    "vue-google-autocomplete": VueGoogleAutocomplete,
  },
  mixins: [
    validationMixin,
    CompanyAddressMixin,
    PhoneMixin,
    ProgramMixin,
    CompanyMixin,
    DirtyDataMixin,
    // For Demo
    NotificationsMixin,
    // For Demo
  ],
  validations: {
    company: {
      companyLegalName: {
        maxLength: maxLength(75),
        required,
      },
      dbaCompanyName: {
        maxLength: maxLength(75),
      },
      programsOfInterest: {
        required,
      },
      numberofLocations: {
        numeric,
      },
      contact: {
        firstName: {
          // alpha,
          maxLength: maxLength(50),
          required,
        },
        lastName: {
          // alpha,
          maxLength: maxLength(50),
          required,
        },
        emailAddress: {
          email,
          maxLength: maxLength(50),
          required,
        },
        title: {
          maxLength: maxLength(50),
          required,
        },
        salutation: {
          maxLength: maxLength(50),
          required,
        },
      },
      phone: {
        phoneNumber: {
          minLength: minLength(14),
          required,
        },
        phoneNumberExtension: {
          maxLength: maxLength(6),
        },
        phoneNumberType: {
          required,
        },
      },
      address: {
        addressLine1: {
          required,
        },
        city: {
          required,
        },
        stateID: {
          required,
        },
        zip: {
          required,
          maxLength: maxLength(10),
        },
      },
    },
  },
  async created() {
    this.company = {
      companyLegalName: "",
      dbaCompanyName: null,
      website: null,
      notes: null,
      numberofLocations: null,
      programsOfInterest: null,
      leadTypeID: null,
      salesMemberID: null,
      leadTypeOther: "",
      contact: {
        firstName: "",
        lastName: "",
        title: "",
        emailAddress: "",
        salutation: "",
      },
      phone: {
        phoneNumber: "",
        phoneNumberExtension: "",
        phoneNumberType: undefined,
      },
      address: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        stateID: undefined,
        zip: undefined,
      },
      isApplicationCompaniesUpdate: false,
    };
    var getCompanyDetails = localStorage.getItem("currentCompany");
    if (getCompanyDetails) {
      var userCompanyDetails = JSON.parse(getCompanyDetails);
    }
    switch (this.$route.name) {
      case "AddNewCompany":
        this.title = "Add New Company";
        break;
      case "AddAdditionalSite":
        this.title = "Add Additional Site";
        this.company.parentCompanyID = userCompanyDetails.companyID;
        break;
    }
    await this.getStates();
    await this.getAllProgramsByType();
    await this.getPhoneTypes();
    this.initDirtyDataWatcher("company");
  },
  methods: {
    ...mapMutations(["setPreserveMessageAfterPush"]),
    async onValidateFormSubmit() {
      const userDetails = localStorage.getItem("userToken");
      this.$v.company.$touch();
      if (!this.$v.company.$error) {
        await this.quickAddNewCompany(this.company);
        if (this.statusCode && this.statusCode === 200) {
          const { UserID } = jwt_decode(userDetails);
          // For Demo
          if (!this.company.parentCompanyID && userDetails) {
            await this.addCompanyToASPNetUser(UserID, this.company.companyID);
            bus.$emit("companyUpdated");
          }
          this.setDirtyData(false);
          this.setPreserveMessageAfterPush(true);

          if (this.$route.name === "AddNewCompany") {
            localStorage.setItem("currentCompany", JSON.stringify(this.company));
            // Bus used to trigger update in SidebarMenu.vue company details
            bus.$emit("newParentCompanyCreated");

            this.$router.push({
              name: "AdditionalSites",
              params: { companyDetails: this.companyDetails },
            });
          }

          this.$router.push({ name: "AdditionalSites" });
        }
      } else {
        this.setDangerMessage("Form must be filled out properly.");
      }
    },
    cancelForm() {
      this.$router.push({ name: "Dashboard" });
    },
    getAddressData: function (addressData) {
      const {
        street_number: streetNumber = "",
        route = "",
        locality = "",
        administrative_area_level_1: stateName = "",
        postal_code: postalCode = "",
      } = addressData;
      const stateId = this.states.find(({ stateCode }) => stateCode === stateName).stateID || "";
      this.company = {
        ...this.company,
        address: {
          addressLine1: `${streetNumber} ${route}`,
          addressLine2: "",
          city: locality,
          stateID: stateId,
          zip: postalCode,
        },
      };
      this.$v.company.address.$touch();
      // wait some time to handleAddressChange reset the input field
      setTimeout(() => {
        this.$refs.autocomplete.update(`${streetNumber} ${route}`);
      }, 500);
    },
    handleAddressChange: function () {
      this.$refs.autocomplete.update("");
    },
  },
  computed: {
    dropdownLimit() {
      if (
        this.company.programsOfInterest !== null &&
        this.company.programsOfInterest !== undefined
      ) {
        if (this.company.programsOfInterest.length > 1) {
          var programLabels = "";

          // Full program names for DMEPOS and CDNC are too long to show more than one program if they're selected first or second
          if (
            this.company.programsOfInterest[0].programDescription.length +
              this.company.programsOfInterest[1].programDescription.length >
            70
          ) {
            return 1;
          }

          for (var i = 0; i < this.company.programsOfInterest.length; i++) {
            programLabels += this.company.programsOfInterest[i].dropdownLabel;
          }

          if (programLabels.length > 70) {
            return 2;
          }
        }

        return 3;
      } else {
        return 3;
      }
    },
  },
};
</script>
